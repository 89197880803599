import React, { Fragment } from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  SvgIcon,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

const ReadAndLook = ({ classes, slug, abstract }) => {
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {abstract.map((ab, index) => {
          const pg = ab.paragraphs
          const image = ab.image.childImageSharp.fluid
          return (
            <Fragment key={index}>
              <Grid item xxs={12} xs={12} sm={8}>
                <Box pt={2}>
                  <Typography className={classes.title}>
                    {ab.headline}
                  </Typography>
                  {pg.map((p, index) => (
                    <Box key={index}>
                      <Typography className={classes.para}>{p}</Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
              <Grid item xxs={12} xs={12} sm={4}>
                <Box pt={2}>
                  <Image
                    fluid={image}
                    alt={`Insert here by ICON Emblem`}
                    className={classes.itemImage}
                  />
                  <Typography variant="caption">{ab.caption}</Typography>
                </Box>
              </Grid>
            </Fragment>
          )
        })}
      </Grid>
    </div>
  )
}

const styles = theme => ({
  root: {
    minWidth: 225,
    flexGrow: 1,
    margin: theme.spacing(2, 0.5, 0.5, 0.5),
    backgroundColor: theme.palette.background.default,
  },

  title: {
    fontSize: "22px",
    letterSpacing: "2px",
    // fontWeight: "bold",
    textTransform: "uppercase",
    marginBottom: theme.spacing(2),
  },
  summary: {
    fontSize: "18px",
    letterSpacing: "0.1px",
    marginTop: theme.spacing(1),
  },

  para: {
    fontSize: "19px",
    lineHeight: 1.4,
    marginTop: theme.spacing(1),
  },
  details: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    maxWidth: "750px",
    backgroundColor: theme.palette.background.default,
  },
})

export default withStyles(styles)(ReadAndLook)
