import React from "react"

import { Box, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

const TextBlock = ({ classes, blockText }) => {
  const { headline, paragraphs } = blockText
  return (
    <div>
      <Typography className={classes.tagline}>{headline}</Typography>
      <Box pl={2} pr={2}>
        {paragraphs.map((paragraph, index) => (
          <Typography className={classes.para} key={index}>
            {paragraph}
          </Typography>
        ))}
      </Box>
    </div>
  )
}

const styles = theme => ({
  root: {
    minWidth: 225,
    flexGrow: 1,
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
    backgroundColor: theme.palette.background.default,
  },

  tagline: {
    fontSize: "28px",
    letterSpacing: "3px",
    // fontWeight: "bold",
    textTransform: "uppercase",
    marginBottom: theme.spacing(2),
  },
  summary: {
    fontSize: "18px",
    letterSpacing: "0.1px",
    marginTop: theme.spacing(1),
  },

  para: {
    fontSize: "17px",
    lineHeight: 1.4,
    marginTop: theme.spacing(1),
  },
  details: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    maxWidth: "750px",
    backgroundColor: theme.palette.background.default,
  },
})
export default withStyles(styles)(TextBlock)
