import React, { useRef } from "react"
import Layout from "../components/Layout/layout"
import ItemSide from "../components/Layout/ItemSide"
import { Box, ButtonBase, Container, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import TinyGallery from "../components/Gallery/TinyGallery"
import { getThemeProps } from "@material-ui/styles"
import Inquiry from "../components/Inquiry"
import TextBlock from "../components/DropIn/TextBlock"
import QuoteBox from "../components/DropIn/QuoteBox"
import ReadAndLook from "../components/DropIn/ReadAndLook"
import FrequentlyAsked from "../components/DropIn/FrequentlyAsked"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useSiteMetadata } from "../components/utils/hooks/useSiteMetadata"

const Item002 = ({ data, classes }) => {
  const inquirySection = useRef()
  const theme = useTheme()
  const smallUp = useMediaQuery(theme.breakpoints.up("sm"))
  const mediumUp = useMediaQuery(theme.breakpoints.up("md"))
  const scrollToInquiry = () =>
    inquirySection.current.scrollIntoView({ behavior: "smooth" })
  const {
    mdx: {
      frontmatter: {
        category,
        caption1,
        caption2,
        title,
        date,
        slug,
        tagline,
        main,
      },
      id,
      body,
    },
  } = data

  const description = data.mdx.exports.description
  const blockText = data.mdx.exports.blockText
  const quotes = data.mdx.exports.quotes
  const abstract = data.mdx.exports.abstract
  const faq = data.mdx.exports.faq
  const img01 = data.mdx.frontmatter.image01.childImageSharp.fluid
  const img02 = data.mdx.frontmatter.image02.childImageSharp.fluid
  const img03 = data.mdx.frontmatter.image03.childImageSharp.fluid
  const img04 = data.mdx.frontmatter.image04.childImageSharp.fluid

  return (
    <Layout>
      <Container className={classes.container}>
        <Grid container spacing={3}>
          {smallUp && (
            <Grid item sm={5} md={3}>
              <ItemSide className={classes.itemSide} />
            </Grid>
          )}
          <Grid item xxs={12} xs={12} sm={7} md={9}>
            <Typography
              component="h1"
              className={classes.itemPageHeader}
              color="inherit"
              gutterBottom
            >
              {title}
            </Typography>

            <Image
              fluid={img01}
              alt={`${title} by ICON Emblem`}
              className={classes.itemImage}
            />
            <div className={classes.spacer} />
            <Grid container spacing={2}>
              <Grid item xxs={12} xs={12} md={9} lg={6}>
                <Typography variant="body1" className={classes.tagline}>
                  {tagline}
                </Typography>
                {description.map((desc, index) => (
                  <Box key={index}>
                    <Typography variant="body1" className={classes.summary}>
                      {desc}
                    </Typography>
                  </Box>
                ))}
              </Grid>
              <Grid item xxs={12} xs={12} md={3} lg={6}>
                <Image
                  fluid={img02}
                  alt={`${title} by ICON Emblem`}
                  className={classes.itemImage}
                />
                <Typography variant="caption">{caption1}</Typography>
                <Image
                  fluid={img03}
                  alt={`${title} by ICON Emblem`}
                  className={classes.itemImage}
                />
                <Typography variant="caption">{caption2}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xxs={12} xs={12} sm={6}>
              <ButtonBase
                className={classes.commonButton}
                onClick={scrollToInquiry}
              >
                Request a Quote
              </ButtonBase>
            </Grid>
            <Grid item xxs={12} xs={12} sm={6}>
              <ButtonBase className={classes.commonButton}>
                View the Full Gallery
              </ButtonBase>
            </Grid>

            <Grid item xxs={12} xs={12}>
              <Image
                fluid={img04}
                alt={`${title} by ICON Emblem`}
                className={classes.itemImage}
              />
            </Grid>
            <Grid item xxs={12} xs={12} sm={6}>
              <ButtonBase
                className={classes.commonButton}
                onClick={scrollToInquiry}
              >
                Request a Quote
              </ButtonBase>
            </Grid>
            <Grid item xxs={12} xs={12} sm={6}>
              <ButtonBase className={classes.commonButton}>
                View the Full Gallery
              </ButtonBase>
            </Grid>
            <Grid item xxs={12} xs={12} sm={8}>
              <TextBlock blockText={blockText} />
            </Grid>
            <Grid item xxs={12} xs={12} sm={4}>
              <QuoteBox quotes={quotes} />
            </Grid>
            <Grid item xxs={12} xs={12}>
              <ReadAndLook slug={slug} abstract={abstract} />
            </Grid>
            <Grid item xxs={12} xs={12}>
              <FrequentlyAsked faq={faq} />
            </Grid>
          </Grid>
          <Box ref={inquirySection} className={classes.inquiryFrame}>
            <Inquiry />
          </Box>
        </Grid>
      </Container>
    </Layout>
  )
}
export const query = graphql`
  query GetSingleItemT002($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        category
        date(formatString: "MMMM Do, YYYY")
        slug
        tagline
        caption1
        caption2
        image01 {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image02 {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image03 {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image04 {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      id
      body
      exports {
        description
        blockText {
          headline
          paragraphs
        }
        quotes {
          id
          quote
          name
          bio
        }
        abstract {
          headline
          paragraphs
          caption
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        faq {
          id
          question
          answer
        }
      }
    }
  }
`
const styles = theme => ({
  root: {
    minWidth: 225,
    flexGrow: 1,
    margin: theme.spacing(0, 0.5, 0.5, 0.5),

    // margin: theme.spacing(1, 0, 0),
  },
  container: {
    marginTop: 25,
    // display: "grid",
  },
  itemImage: {
    maxWidth: "100%",
  },
  itemPageHeader: {
    fontSize: "38px",
    letterSpacing: "2px",
    // fontWeight: "bold",
    textTransform: "uppercase",
  },
  tagline: {
    fontSize: "32px",
    letterSpacing: "3px",
    // fontWeight: "bold",
    textTransform: "uppercase",
  },
  summary: {
    fontSize: "21px",
    letterSpacing: "0.1px",
    lineHeight: 1.3,
    marginTop: theme.spacing(1),
  },
  spacer: {
    marginTop: theme.spacing(2),
  },
  commonButton: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    width: "100%",
    // margin: theme.spacing(1),
    fontSize: "18px",
    textTransform: "uppercase",
    letterSpacing: "2px",
  },
  mdxBox: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  inquiryFrame: {
    marginTop: theme.spacing(3),
  },
})
export default withStyles(styles)(Item002)
