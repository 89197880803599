import React from "react"
import { Link } from "gatsby"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  SvgIcon,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

const QuoteBox = ({ classes, quotes }) => {
  return (
    <div>
      {quotes.map(quote => (
        <Box key={quote.id}>
          <Typography className={classes.quotation} variant="body2">
            {quote.quote}
          </Typography>
          <div className={classes.spacer} />
          <Typography className={classes.credit} variant="caption">
            -{quote.name}, {quote.bio}
          </Typography>
        </Box>
      ))}
    </div>
  )
}

const styles = theme => ({
  root: {
    minWidth: 225,
    flexGrow: 1,
    margin: theme.spacing(0, 0.5, 0.5, 0.5),

    // margin: theme.spacing(1, 0, 0),
  },
  container: {
    marginTop: 25,
    // display: "grid",
  },
  itemImage: {
    maxWidth: "100%",
  },
  itemPageHeader: {
    fontSize: "42px",
    letterSpacing: "3px",
    // fontWeight: "bold",
    textTransform: "uppercase",
  },
  tagline: {
    fontSize: "32px",
    letterSpacing: "3px",
    // fontWeight: "bold",
    textTransform: "uppercase",
  },
  summary: {
    fontSize: "18px",
    letterSpacing: "0.1px",
    marginTop: theme.spacing(1),
  },
  quotation: {
    fontSize: "15px",
    fontWeight: "bold",
  },
  credit: {
    fontSize: "14px",
  },
  spacer: {
    marginTop: theme.spacing(2),
  },
  commonButton: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    width: "100%",
    // margin: theme.spacing(1),
    fontSize: "18px",
    textTransform: "uppercase",
    letterSpacing: "2px",
  },
  mdxBox: {
    marginTop: theme.spacing(3),
  },
  details: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
})
export default withStyles(styles)(QuoteBox)
