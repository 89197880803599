import React from "react"
import { Link } from "gatsby"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  SvgIcon,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
const FrequentlyAsked = ({ classes, faq }) => {
  return (
    <div>
      <Typography className={classes.tagline}>
        Frequently Asked Questions (FAQ)
      </Typography>

      {faq.map(fq => {
        const ans = fq.answer
        var res = ans.split("--")
        return (
          <Accordion
            square
            key={fq.id}
            className={classes.details}
            variant="outlined"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${fq.id}-content`}
              id={`panel${fq.id}-header`}
            >
              <Avatar className={classes.number} variant="square">
                {fq.id}
              </Avatar>

              <Typography className={classes.faqQuestion}>
                {fq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <div>
                {res.map(a => (
                  <Typography className={classes.faqAnswer}>{a}</Typography>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        )
      })}
    </div>
  )
}

const styles = theme => ({
  root: {
    minWidth: 225,
    flexGrow: 1,
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
    backgroundColor: theme.palette.background.default,
  },
  container: {
    marginTop: 25,
    // display: "grid",
  },
  details: {
    width: "100%",
  },
  tagline: {
    fontSize: "28px",
    letterSpacing: "3px",
    // fontWeight: "bold",
    textTransform: "uppercase",
    marginBottom: theme.spacing(2),
  },
  summary: {
    fontSize: "18px",
    letterSpacing: "0.1px",
    marginTop: theme.spacing(1),
  },
  number: {
    fontSize: "14px",
    fontWeight: "bold",
    marginRight: theme.spacing(3),
    color: theme.palette.primary.main,
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  faqQuestion: {
    fontSize: "17px",
    fontWeight: "light",
    paddingTop: theme.spacing(1),
  },
  faqAnswer: {
    fontSize: "17px",
    marginTop: theme.spacing(1),
  },
  details: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    maxWidth: "750px",
    backgroundColor: theme.palette.background.default,
  },
})
export default withStyles(styles)(FrequentlyAsked)
